import { ref, watch } from '@vue/composition-api'
import store from '@/store'
import periodType from '@/constants/period-type'
import paymentStatus from '@/constants/payment-status'
import paymentMethodChannel from '@/constants/channel-code'
import { formatCurrency } from '@/@core/utils/filter'
import {
  mdiPageFirst,
  mdiPageLast,
  mdiChevronLeft,
  mdiChevronRight,
} from '@mdi/js'

export default function usePaymentList() {
  const paymentsTableColumns = [
    {
      text: 'UID', value: 'paymentCode', sortable: false, width: 220,
    },
    {
      text: 'ORDER ID', value: 'orderId', sortable: false, width: 220,
    },
    {
      text: 'CREATED ON', value: 'createdAt', align: 'center', width: 150,
    },
    {
      text: 'SHOP',
      value: 'shopName',
      sortable: false,
      align: 'center',
      width: 250,
    },
    {
      text: 'AMOUNT',
      value: 'amount',
      sortable: false,
      align: 'center',
      width: 150,
    },
    {
      text: 'BILLING AMOUNT',
      value: 'voucherDetail',
      sortable: false,
      align: 'center',
      width: 150,
    },
    {
      text: 'RATE DETAILS',
      value: 'rateDetails',
      sortable: false,
      align: 'center',
      width: 150,
    },
    {
      text: 'PAYMENT METHOD',
      value: 'paymentMethod',
      sortable: false,
      align: 'center',
      width: 150,
    },
    {
      text: 'STATUS',
      value: 'paymentStatus',
      sortable: false,
      align: 'center',
      width: 180,
    },
    {
      text: 'CUSTOMER',
      value: 'customer',
      sortable: false,
      align: 'center',
      width: 100,
    },
    {
      text: 'DESCRIPTION',
      value: 'description',
      sortable: false,
      align: 'center',
      width: 200,
    },
  ]

  const showDateRange = ref(false)

  const paymentItems = ref([])
  const paymentItemsLength = ref(0)

  const searchFilterModel = ref('')

  const shopFilterModel = ref(null)
  const shopFilterItems = ref([])

  const statusFilterModel = ref(null)
  const statusFilterItems = ref([])

  const channelFilterModel = ref(null)
  const channelFilterItems = ref([])

  const countryFilterModel = ref(null)
  const countryFilterItems = ref([])
   
  const paymentMethodFilterModel = ref(null)
  const paymentMethodFilterItems = ref([])

  const dateFilterModel = ref([
    new Date().toISOString().substring(0, 10),
    new Date().toISOString().substring(0, 10),
  ])

  const periodTypeItems = ref([])
  const periodTypeModel = ref(null)

  const loading = ref(false)

  const options = ref({
    sortBy: ['p.creationDate'],
    sortDesc: [true],
  })

  const footerTableProps = {
    showFirstLastPage: true,
    itemsPerPageOptions: [10, 20, 50, 100],
    showCurrentPage: true,
    firstIcon: mdiPageFirst,
    lastIcon: mdiPageLast,
    prevIcon: mdiChevronLeft,
    nextIcon: mdiChevronRight,
  }

  const itemsPerPage = ref(footerTableProps[0])

  const snackbar = ref({
    isSnackbarVisible: false,
    snackBarColor: 'info',
    snackbarMessage: '',
    snackbarTimeOut: '3000',
  })

  const menuDateRange = ref(false)

  const fetchList = () => {
    loading.value = true
    store
      .dispatch('processing-payments/fetchList', {
        search: searchFilterModel.value,
        options: options.value,
        statusCode: statusFilterModel.value,
        countryCode: countryFilterModel.value,
        legacyCode: paymentMethodFilterModel.value,
        shopId: shopFilterModel.value,
        channelCode: channelFilterModel.value,
        periodType: periodTypeModel.value,
        from: dateFilterModel.value[0],
        to: dateFilterModel.value[1],
      })
      .then(response => {
        const { records, count } = response.data
        paymentItems.value = records
        paymentItemsLength.value = count
      })
      .catch(error => error)
      .finally(() => {
        loading.value = false
      })
  }

  const onSearch = keyword => {
    if (searchFilterModel.value === '') return
    fetchList()
  }

  const fetchConfig = () => {
    loading.value = true
    store
      .dispatch('processing-payments/fetchConfig')
      .then(response => {
        const config = response.data
        shopFilterItems.value = config.shops
        statusFilterItems.value = config.paymentStatus
        channelFilterItems.value = config.channels
        countryFilterItems.value = config.countries
        paymentMethodFilterItems.value = config.paymentMethods
        periodTypeItems.value = config.periodTypes
        periodTypeModel.value = periodTypeItems.value.find(
          x => x.value === periodType.DAY,
        ).value
      })
      .catch(error => error)
      .finally(() => {
        loading.value = false
      })
  }

  const downloadList = () => {
    store
      .dispatch('processing-payments/export', {
        search: searchFilterModel.value,
        options: options.value,
        statusCode: statusFilterModel.value,
        countryCode: countryFilterModel.value,
        legacyCode: paymentMethodFilterModel.value,
        shopId: shopFilterModel.value,
        channelCode: channelFilterModel.value,
        periodType: periodTypeModel.value,
        from: dateFilterModel.value[0],
        to: dateFilterModel.value[1],
      })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        const fileName = response.headers.filename
        link.setAttribute('download', fileName)
        document.body.appendChild(link)
        link.click()
        link.remove()
        window.URL.revokeObjectURL(url)
      })
      .catch(error => error)
      .finally(() => {
        loading.value = false
      })
  }

  const onPeriodTypeChange = () => {
    showDateRange.value = periodTypeModel.value === periodType.CUSTOM
  }

  const onCountryChange = code => {
    loading.value = true
    store
      .dispatch('processing-payments/fetchPaymentMethodsByCountryConfig', {
        countryCode: code,
      })
      .then(response => {
        paymentMethodFilterItems.value = response.data
      })
      .catch(error => error)
      .finally(() => {
        loading.value = false
      })
  }

  watch([options], () => {
    fetchList()
  })

  const updateDataTableRow = row => {
    paymentItems.value.find(
      x => x.settlementCode === row.value.settlementCode,
    ).value = row.value
  }

  const applyFilters = () => {
    options.value.page = 1
    searchFilterModel.value = null
    fetchList()
  }

  const resetFilters = () => {
    searchFilterModel.value = ''
    shopFilterModel.value = null
    statusFilterModel.value = null
    channelFilterModel.value = null
    periodTypeModel.value = periodTypeItems.value.find(x => x.value === periodType.DAY).value
    showDateRange.value = false
    dateFilterModel.value = [
      new Date().toISOString().substring(0, 10),
      new Date().toISOString().substring(0, 10),
    ]
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*
  const resolveStatusVariant = status => {
    switch (status) {
      case paymentStatus.PENDING:
        return 'payment-status--pending'
      case paymentStatus.PAID:
        return 'payment-status--paid'
      case paymentStatus.EXPIRED:
        return 'payment-status--expired'
      case paymentStatus.FAILED:
        return 'payment-status--failed'
      default:
        return 'payment-status--pending'
    }
  }

  const resolvePaymentMethodChannel = channelCode => {
    if (channelCode === paymentMethodChannel.CASH) return 'success'
    if (channelCode === paymentMethodChannel.ONLINE) return 'warning'
    if (channelCode === paymentMethodChannel.CRYPTO) return 'primary'
    if (channelCode === paymentMethodChannel.CREDIT_CARD) return 'error'

    return 'success'
  }

  const resolveRateDetails = rateDetails => {
    let html = ''
    if (rateDetails) {
      rateDetails.forEach(rd => {
        if (rd.minRateDiscount > 0) {
          html += `$ ${formatCurrency(rd.minRateDiscount)} (Min rate)`
        } else {
          const rateDetail = rd.fixed > 0 ? `(${formatCurrency(rd.percentage)}% + $ ${formatCurrency(rd.fixed)})` : `(${formatCurrency(rd.percentage)}%)`
          html += rd.totalDiscount > 0 ? `$ ${formatCurrency(rd.totalDiscount)} ${rateDetail}` : ''
        }
      })
    }

    return html
  }

  return {
    paymentItems,
    paymentItemsLength,
    paymentsTableColumns,
    footerTableProps,
    itemsPerPage,
    searchFilterModel,
    shopFilterItems,
    shopFilterModel,
    statusFilterModel,
    statusFilterItems,
    channelFilterModel,
    channelFilterItems,
    countryFilterModel,
    countryFilterItems,
    paymentMethodFilterModel,
    paymentMethodFilterItems,
    periodTypeModel,
    periodTypeItems,
    dateFilterModel,
    menuDateRange,
    loading,
    options,
    showDateRange,
    snackbar,
    resolveRateDetails,
    applyFilters,
    resetFilters,
    fetchList,
    fetchConfig,
    onSearch,
    downloadList,
    updateDataTableRow,
    resolveStatusVariant,
    resolvePaymentMethodChannel,
    onPeriodTypeChange,
    onCountryChange,
  }
}
