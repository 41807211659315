<template>
  <div id="payment-list">
    <v-card>
      <v-card-title>Payments</v-card-title>
      <v-row class="px-2 ma-0">
        <v-col
          cols="12"
          sm="4"
        >
          <v-select
            v-model="periodTypeModel"
            placeholder="Range Type"
            :items="periodTypeItems"
            item-text="text"
            item-value="value"
            outlined
            dense
            hide-details
            @change="onPeriodTypeChange"
          ></v-select>
        </v-col>

        <v-col
          v-show="showDateRange"
          cols="12"
          sm="4"
          class="pt-0 pb-0"
        >
          <v-menu
            ref="menuDateRangeFilter"
            v-model="menuDateRange"
            :return-value.sync="dateFilterModel"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            hide-details
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dateRangeText"
                label="Dates Range"
                :prepend-icon="icons.mdiCalendar"
                readonly
                v-bind="attrs"
                hide-details
                v-on="on"
              ></v-text-field>
            </template>

            <v-date-picker
              v-model="dateFilterModel"
              range
              no-title
              scrollable
              hide-details
            >
              <v-btn
                block
                outlined
                small
                dense
                @click="$refs.menuDateRangeFilter.save(dateFilterModel)"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>

        <v-col
          cols="12"
          sm="4"
        >
          <v-autocomplete
            v-model="shopFilterModel"
            placeholder="Select Shop"
            :items="shopFilterItems"
            item-text="text"
            item-value="value"
            outlined
            dense
            clearable
            hide-details
          ></v-autocomplete>
        </v-col>

        <v-col
          cols="12"
          sm="4"
        >
          <v-autocomplete
            v-model="countryFilterModel"
            placeholder="Country"
            :items="countryFilterItems"
            item-text="text"
            item-value="value"
            outlined
            dense
            clearable
            hide-details
            @change="onCountryChange"
          ></v-autocomplete>
        </v-col>

        <v-col
          cols="12"
          sm="4"
        >
          <v-autocomplete
            v-model="paymentMethodFilterModel"
            placeholder="Payment Method"
            :items="paymentMethodFilterItems"
            item-text="text"
            item-value="value"
            outlined
            dense
            clearable
            hide-details
            :loading="loading"
          ></v-autocomplete>
        </v-col>

        <!-- status filter -->
        <v-col
          cols="12"
          sm="2"
        >
          <v-select
            v-model="statusFilterModel"
            placeholder="Select Status"
            :items="statusFilterItems"
            item-text="text"
            item-value="value"
            outlined
            dense
            clearable
            hide-details
          ></v-select>
        </v-col>

        <v-col
          cols="12"
          sm="2"
        >
          <v-select
            v-model="channelFilterModel"
            placeholder="Select Channel"
            :items="channelFilterItems"
            item-text="text"
            item-value="value"
            outlined
            dense
            clearable
            hide-details
          ></v-select>
        </v-col>
      </v-row>

      <v-divider class="mt-4"></v-divider>

      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <v-btn
          ref="btnApplyFilters"
          color="primary"
          class="me-3 mb-4"
          :loading="loading"
          @click.prevent="applyFilters"
        >
          Apply Filters
        </v-btn>
        <v-btn
          color="secondary"
          outlined
          type="reset"
          class="me-3 mb-4"
          @click.prevent="resetFilters"
        >
          Reset
        </v-btn>

        <v-spacer></v-spacer>

        <div class="d-flex align-center flex-wrap">
          <v-text-field
            v-model="searchFilterModel"
            :append-icon="icons.mdiMagnify"
            label="Search"
            single-line
            hide-details
            dense
            outlined
            class="mb-4 me-3 searchBy"
            @keyup.enter="onSearch"
            @click:append="onSearch"
          >
          </v-text-field>

          <v-btn
            color="secondary"
            outlined
            class="mb-4 me-0"
            :disabled="paymentItemsLength == 0"
            @click="downloadList"
          >
            <v-icon
              size="17"
              class="me-1"
            >
              {{ icons.mdiExportVariant }}
            </v-icon>
            <span>Export</span>
          </v-btn>
        </div>
      </v-card-text>

      <!-- table -->
      <v-data-table
        ref="paymentsTable"
        :headers="paymentsTableColumns"
        :items="paymentItems"
        :options.sync="options"
        :server-items-length="paymentItemsLength"
        :items-per-page="itemsPerPage"
        :loading="loading"
        show-current-page
        loading-text="Loading... Please wait"
        :footer-props="footerTableProps"
      >
        <!-- UID -->
        <template #[`item.paymentCode`]="{ item }">
          {{ item.paymentCode }}
        </template>

        <!-- Created At  -->
        <template #[`item.createdAt`]="{ item }">
          <span>{{ formatDateAndTime(item.createdAt) }}</span>
        </template>

        <template #[`item.paymentMethod`]="{ item }">
          <div class="table-row">
            {{ item.paymentMethod.name }}
          </div>
          <div class="table-row">
            <v-chip
              small
              :color="
                resolvePaymentMethodChannel(item.paymentMethod.channelCode)
              "
              :class="`${resolvePaymentMethodChannel(
                item.paymentMethod.channelCode
              )}--text`"
              class="v-chip-light-bg"
            >
              {{ item.paymentMethod.channelName }}
            </v-chip>
          </div>
          <div class="table-row">
            {{ item.paymentMethod.countryName }}
          </div>
        </template>

        <!-- Amount -->
        <template #[`item.amount`]="{ item }">
          <span
            class="text-capitalize font-weight-semibold text--primary align-right"
          >{{ formatCurrency(item.amount) }} {{ item.currencyCode }}</span>
        </template>

         <!-- Amount -->
         <template #[`item.voucherDetail`]="{ item }">
          <span
            class="text-capitalize font-weight-semibold text--primary align-right"
          >{{ formatCurrency(item.voucherDetail.amount) }} {{ item.voucherDetail.currency }}</span>
        </template>

        <template #[`item.rateDetails`]="{ item }">
          <span>{{ resolveRateDetails(item.rateDetails) }}</span>
        </template>

        <!-- Status -->
        <template #[`item.paymentStatus`]="{ item }">
          <v-chip
            small
            :color="resolveStatusVariant(item.paymentStatus.statusCode)"
            :class="`${resolveStatusVariant(
              item.paymentStatus.statusCode
            )}--text`"
            class="v-chip-light-bg font-weight-semibold text-capitalize"
          >
            {{ item.paymentStatus.statusCode }}
          </v-chip>
          <div class="notifiedAt">
            {{ formatDateAndTime(item.paymentStatus.statusUpdatedAt) }}
          </div>
        </template>

        <template #[`item.customer`]="{ item }">
          <div>{{ item.customer.email }}</div>
          <div>
            {{ `${item.customer.firstName} ${item.customer.lastName}` }}
          </div>
        </template>

        <!-- actions -->

        <template #[`item.actions`]="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                color="primary"
                dark
                v-bind="attrs"
                v-on="on"
              >
                {{ icons.mdiEyeOutline }}
              </v-icon>
            </template>
            <span>View</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
// sidebar
import {
  avatarText,
  formatDate,
  formatDateAndTime,
  formatCurrency,
} from '@core/utils/filter'
import {
  mdiExportVariant,
  mdiPlus,
  mdiCalendar,
  mdiMagnify,
  mdiEyeOutline,
} from '@mdi/js'
import {
  computed, onMounted, onUnmounted, ref,
} from '@vue/composition-api'
import store from '@/store'
import storeModule from '../storeModule'
import usePaymentList from './usePaymentList'

export default {
  setup() {
    const STORE_MODULE_NAME = 'processing-payments'

    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) {
      store.registerModule(STORE_MODULE_NAME, storeModule)
    }

    const {
      paymentsTableColumns,
      searchFilterModel,
      shopFilterModel,
      shopFilterItems,
      statusFilterModel,
      statusFilterItems,
      channelFilterModel,
      channelFilterItems,
      paymentMethodFilterModel,
      paymentMethodFilterItems,
      countryFilterModel,
      countryFilterItems,
      periodTypeModel,
      periodTypeItems,
      dateFilterModel,
      menuDateRange,
      paymentItems,
      paymentItemsLength,
      itemsPerPage,
      footerTableProps,
      options,
      loading,
      showDateRange,
      snackbar,
      resolveRateDetails,
      fetchList,
      onSearch,
      applyFilters,
      resetFilters,
      fetchConfig,
      downloadList,
      resolveStatusVariant,
      resolvePaymentMethodChannel,
      onPeriodTypeChange,
      onCountryChange,
    } = usePaymentList()

    const dateRangeText = computed(() => dateFilterModel.value.join(' ~ '))

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) {
        store.unregisterModule(STORE_MODULE_NAME)
      }
    })

    onMounted(() => {
      fetchConfig()
    })

    return {
      paymentItems,
      paymentItemsLength,
      paymentsTableColumns,
      searchFilterModel,
      shopFilterModel,
      shopFilterItems,
      statusFilterModel,
      statusFilterItems,
      channelFilterModel,
      channelFilterItems,
      paymentMethodFilterModel,
      paymentMethodFilterItems,
      countryFilterModel,
      countryFilterItems,
      periodTypeModel,
      periodTypeItems,
      dateFilterModel,
      dateRangeText,
      menuDateRange,
      loading,
      options,
      showDateRange,
      snackbar,
      footerTableProps,
      itemsPerPage,
      resolveRateDetails,
      avatarText,
      resolveStatusVariant,
      resolvePaymentMethodChannel,
      fetchList,
      onSearch,
      applyFilters,
      resetFilters,
      fetchConfig,
      downloadList,
      formatDate,
      formatCurrency,
      formatDateAndTime,
      onPeriodTypeChange,
      onCountryChange,

      // icons
      icons: {
        mdiPlus,
        mdiExportVariant,
        mdiCalendar,
        mdiMagnify,
        mdiEyeOutline,
      },
    }
  },
}
</script>

<style scoped>
.datePicker {
  margin-top: -5px;
}
.notifiedAt {
  font-size: 11px;
  padding: 5px;
}
.table-row {
  padding-top: 5px;
  padding-bottom: 5px;
}
.searchBy {
  width: 380px;
}
</style>
